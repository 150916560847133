import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic_evenement_1 from '../assets/images/pic_evenement_1.jpg'
import pic_evenement_2 from '../assets/images/pic_evenement_2.jpg'
import Biographie from "../components/Biographie";

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe evenementiel</title>
            <meta name="description" content="Photos evenementiel Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Evénementiel</h2>
                    </header>
                    <p>
                        J'ai couvert de nombreuses manifestations culturelles au travers de la France.
                        <br/>Je suis disponible pour vos projets (concerts, théâtre, expositions, comité d'entreprise)
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <span className="image "><img src={pic_evenement_1} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Concerts / spectacles</h3>
                            </header>
                            <p>
                                Vous organisez un évènement, une soirée d'entreprise ? Vous souhaitez divertir vos invités, vos clients, vos employés et leur offrir un souvenir de votre évènement.                                <br/>Je suis disponible pour vos projets (concerts, théâtre, expositions).
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <span className="image "><img src={pic_evenement_2} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <p>
                                Je vous propose une animation photo originale et conviviale lors de votre évènement, soirée d'entreprise, animation de noël et célébrations en tous genres.
                                <br/>Réaliser des photos lors d'un évènement que vous organisez vous permettra d'assurer une bonne communication.
                                <br/>Je me déplace sur le lieux de votre évènement et je prends en photo vos invités, vos clients, vos employés.
                            </p>
                        </div>
                    </div>
                </section>
            </section>
            <Biographie/>

        </div>

    </Layout>
)

export default Landing